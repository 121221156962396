import { computed } from "vue";
import { ref } from "vue";
import { useStore } from "vuex"

export default function (onlyText) {

	const store = useStore();
	const profile = computed(() => store.state.profile.profile);
	const selectedCompany = computed(() => store.state.company.selectedCompany);

	const cityAjax = ref({
		minimumInputLength: 3,

		ajax: {
			delay: 500,
			url: function (params) {
				return `${process.env.VUE_APP_BACK}/api/addresses/${params.term}`;
			},
			beforeSend: function (request) {
				request.setRequestHeader(
					"Authorization",
					`Bearer ${profile.value.token}`
				);
			},
			processResults: function (data) {
				const results = [];
				for (const [key, value] of Object.entries(data)) {
					results.push({ id: key, text: value });
				}
				return {
					results,
				};
			},
			cache: true,
		},
	})

	const usersAjax = ref({
		minimumInputLength: 3,
		ajax: {
			delay: 500,
			url: function (params) {
				return `${process.env.VUE_APP_BACK}/api/users/search/${params.term}`
					+ (selectedCompany.value ? `?company_id=${selectedCompany.value}` : '');
			},
			beforeSend: function (request) {
				request.setRequestHeader(
					"Authorization",
					`Bearer ${profile.value.token}`
				);
			},
			processResults: function (data) {
				let results;
				if (!onlyText) {
					results = data.map((user) => ({
						text: `${user.name} (${user.email})`,
						id: user.id,
					}));
				}
				else {
					results = data.map((user) => ({
						text: user.name,
						id: user.name
					}));
				}
				return { results };
			},
			cache: true,
		},
	})

	return {
		cityAjax, usersAjax
	}
}