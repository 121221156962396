<template>
  <div class="card display--flex">
    <div class="card-header card-header-divider">
      <div class="card-title">Создание заказа</div>
    </div>
    <div class="card-body">
      <order-form @changeData="setNewInfo" :repurchase-info="repurchaseDataInfo" />
      <order-table @change="setNewProducts" :repurchase-data="repurchaseData" class="order-table" />
      <div class="mt-25 mb-10 display--flex justify-content-end">
        <button type="button" @click="submitOrder" class="btn btn-accent mr-10">
          Создать
        </button>
        <button type="button" @click="router.go(-1)" class="btn btn-w">
          Назад
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useErrors from "../../mixins/useErrors";
import { reactive, ref } from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import { useRouter } from "vue-router";
import standartError from "../../mixins/standartError";
import CustomInput from "@/components/Forms/Fields/CustomInput";
import OrderForm from "../../feature/order/create-order/OrderForm.vue";
import OrderTable from "../../feature/order/create-order/OrderTable.vue";

export default {
  components: { CustomInput, CustomSelect2, OrderForm, OrderTable },
  name: "create-orders",
  setup() {
    const store = useStore(),
      router = useRouter(),
      { setErrors, clearCustomErrors } = useErrors();
    const form = reactive({
      user: null,
      products: null,
      entities: null,
      crm_id: null,
      comment: null,
    });

    const currentOrder = store.state.relations.currentOrder

    const repurchaseDataInfo = {
      user: currentOrder?.user_id || null,
      crm_id: currentOrder?.id || null,
      comment: currentOrder?.comment || null,
    }

    const productsData = store.state.relations.currentOrder?.products
    const entitiesData = store.state.relations.currentOrder?.entities
    const repurchaseData = []
    const setRepurchaseData = (arr) => {
      arr.forEach((data) => {
        repurchaseData.push({
          product: data?.product || "-",
          name: data?.name || null,
          count: data?.count || null,
          price: data?.price || null,
          slug: data?.slug || null,
        })
      })
    }
    productsData ? setRepurchaseData(productsData) : null
    entitiesData ? setRepurchaseData(entitiesData) : null
    const positions = ref([]);

    const submitOrder = () => {
      const products = [];
      const entities = [];
      let allSum = 0;
      positions.value.forEach((position) => {
        if (position.product) {
          products.push({
            count: position.count,
            id: Number(position.id),
            name: position.name,
            price: position.price,
            slug: position.slug
          });
          allSum += Number(position.price);
        } else {
          entities.push({
            count: position.count,
            id: position.id,
            name: position.name,
            source: position.source || null,
            access_duration: position.access_duration || null,
          });
        }
      });
      store
        .dispatch("relations/createOrder", {
          user_id: Number(form.user),
          products: JSON.stringify(products),
          entities: JSON.stringify(entities),
          status: "paid",
          crm_id: form.crm_id,
          comment: form.comment,
          price: allSum,
        })
        .then(() => router.push("/orders"))
        .catch(standartError(setErrors, clearCustomErrors));
    };

    const setNewInfo = (data) => {
      form.comment = data.comment;
      form.user = data.user;
      form.crm_id = data.crm_id;
    };

    const setNewProducts = (data) => {
      positions.value = data;
    };

    return {
      form,
      submitOrder,
      router,
      setNewProducts,
      setNewInfo,
      repurchaseDataInfo,
      repurchaseData
    };
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  margin-top: 25px;
  border: 2px solid var(--cream-color);
  padding: 10px 0;
}
</style>